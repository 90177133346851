import { useRef } from 'react';
import { styled } from 'Theme/stitches.config';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { VariantProvider } from 'Shared/Providers/VariantProvider';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import AttributeArea from './AttributeArea';
import useMedia from 'Shared/Hooks/useMedia';
import ArticleProductPageModel from 'Models/Pages/ProductPage/ArticleProductPageModel.interface';
import ProductAccordion from './ProductAccordion';
import ProductCardImage from 'Commerce/Molecules/ProductCard/ProductCardImage';
import { ImageScalingTypes } from 'Shared/Common/ResizeImageEgmontCDN';
import { scrollToElementByRef } from 'Shared/Common/Helpers';

function ProductPage() {
  const productData = useCurrentPage<ArticleProductPageModel>();
  const isDesktop = useMedia(mediaQueryTypes.bpMin961);
  const accordionElementRef = useRef<null | HTMLDivElement>(null);
  const { contentArea } = productData;

  const renderProductAccordion = () => {
    return (
      <ProductAccordionWrapper ref={accordionElementRef}>
        <ProductAccordion items={productData.accordions} />
      </ProductAccordionWrapper>
    );
  };

  return (
    <VariantProvider>
      <ContentContainer>
        <Top>
          <AttributeArea
            openFirstAccordionItem={() =>
              scrollToElementByRef(accordionElementRef)
            }
            productPage={productData}
          />
          <ProductImageWrapper>
            <ProductCardImage
              image={productData.mainImage}
              imageScalingType={ImageScalingTypes.SUBSCRIPTION_OFFER_PAGE}
              discountTagCss={discountTagStyles}
            />
            {isDesktop && renderProductAccordion()}
          </ProductImageWrapper>
        </Top>
        {!isDesktop && renderProductAccordion()}
      </ContentContainer>

      {contentArea && contentArea.length > 0 && (
        <Section css={ContentSection}>
          <Bottom>
            {Array.isArray(contentArea) && (
              <ContentArea childItems={contentArea} />
            )}
          </Bottom>
        </Section>
      )}
    </VariantProvider>
  );
}

const Section = styled('section', {
  position: 'relative',
  maxW: '100%',
});

const Top = styled('div', {
  g: 0,
  w: '100%',

  '@bpMin721': {
    display: 'grid',
    gridTemplateColumns: '1.3fr 1fr',
    g: '$s200',
    mb: '$s500',
  },
  '@bpMax720': {
    display: 'flex',
    flexDirection: 'column',
  },
});

const ProductAccordionWrapper = styled('div', {
  mb: 4,
  '@bpMin961': {
    mb: 12,
  },

  order: 2,
});

const ProductImageWrapper = styled('div', {
  position: 'relative',
  px: 0,
  w: '100%',
  mb: '$s150',
  '@bpMin961': {
    mb: 0,
  },
});

const ContentSection = {
  px: 0,
};

const Bottom = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  pl: 0,
  h: 'auto',
  w: '100%',
  borderBlockStart: '10px',
});

const discountTagStyles = {
  t: 8,
  r: 8,
  fontSize: '$fontSize500',
  lineHeight: '1',
  fontFamily: 'fontSemiBold',
  px: 3,
  py: 2,
  '@bpMax720': {
    fontSize: '$fontSize100',
    t: 6,
    r: 6,
  },
};

export default ProductPage;
